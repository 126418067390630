import moment, { now } from "moment";
import { dateRanges } from "components/NewCalendar/ranges.js";
import CalculatedMeasures from "utils/reports/CalculatedMeasures.js";
import {
  PLANS_HIERARCHY,
  RECOMMENDED_MARKETS_BY_COUNTRY,
  RECOMMENDED_NAMES_MARKETS_BY_COUNTRY,
  SEGMENT_EVENT,
} from "./app/appConstants";
import cubejs, { HttpTransport } from "@cubejs-client/core";
import { CUBEJS_API_URL } from "services/cubejs/constants";
import { enqueueSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import { fillCosts } from "services/almiqui/costs/request";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

import "moment/locale/es"; // Importar el idioma español

moment.locale("es"); // Configurar moment.js para usar español
const FORMAT = "YYYY-MM-DD";

export const TODAY = {
  day: moment(),
  start: moment().format(FORMAT),
  end: moment().format(FORMAT),
};

const IS_DEV_MODE = process.env.REACT_APP_DEV_MODE === "true";
const IS_DEMO = process.env.REACT_APP_DEMO.toLowerCase() === "true";

export const uniqueId = () => parseInt(Date.now() * Math.random()).toString();

export let currencyFormat = Intl.NumberFormat("en-US");
export const formatCurrency = (value, includeDecimals = false) => {
  const options = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: includeDecimals ? 2 : 0,
    maximumFractionDigits: includeDecimals ? 2 : 0,
  };
  return new Intl.NumberFormat("es-US", options).format(value);
};
/**
 *
 * @param {json to sort} jsonObject
 * @param {sort by key} key
 * @returns
 */
export function JSONSort(jsonObject, key) {
  return jsonObject.sort((a, b) => {
    const objA = a[key].toLowerCase();
    const objB = b[key].toLowerCase();
    if (objA < objB) {
      return -1;
    }
    if (objA > objB) {
      return 1;
    }
    // names must be equal
    return 0;
  });
}

/**
 *
 * @param {json to sort by number value} jsonObject
 * @param {sort by key} key
 * @param {sort by citeria: asc desc} creiteria
 * @returns
 */
export function JSONSortByNumber(jsonObject, key, creiteria) {
  return jsonObject.sort((a, b) => {
    const valueA = parseInt(a[key]);
    const valueB = parseInt(b[key]);
    if (creiteria == "asc") return valueA - valueB;
    return valueB - valueA;
  });
}
/**
 *
 * @param {config} reportConfig
 * @returns
 */
export const getPrevPeriod = (reportConfig) => {
  let timeStart = "";
  let timeEnd = "";

  const { dateRange } = reportConfig.timeDimensions[0];

  if (typeof dateRange === "string") {
    timeStart = dateRanges[dateRange].start;
    timeEnd = dateRanges[dateRange].end;
  } else {
    timeStart = dateRange[0];
    timeEnd = dateRange[1];
  }

  // Validar que timeEnd no sea una fecha en el futuro
  const today = moment().format("YYYY-MM-DD");
  if (moment(timeEnd).isAfter(today)) {
    timeEnd = today;
  }
  return { timeStart, timeEnd };
};

/**
 *
 * @param {comparator ["PW","PM","PY"]} comparator
 * @returns
 */
export const comparatorToTime = (comparator) => {
  switch (comparator) {
    case "PW":
      return "weeks";
    case "PM":
      return "months";
    case "PY":
      return "years";
    default:
      break;
  }
};

export const dateRangesWithCurrentDay = {
  Today: true,
  "This week": true,
  "This month": true,
  "Last 7 days": true,
  "Last 30 days": true,
  "Last 60 days": true,
};

/**
 *
 * @param {config} reportConfig
 * @returns
 */
export const getTimeDimension = (reportConfig) => {
  const { dateRange: dateRangeConfig } = reportConfig.timeDimensions[0];
  const { timeStart, timeEnd } = getPrevPeriod(reportConfig);

  let dateRange = [
    moment(timeStart).format(FORMAT),
    moment(timeEnd).format(FORMAT),
  ];
  // Validar que timeEnd no sea una fecha en el futuro
  const today = moment().format("YYYY-MM-DD");
  if (
    dateRangesWithCurrentDay[dateRangeConfig] ||
    moment(timeEnd).isAfter(today) ||
    moment(dateRangeConfig?.[1]).isSame(today)
  ) {
    const currentTimeEnd = moment()
      .add(1, "hour")
      .startOf("hour")
      .format("YYYY-MM-DDTHH:00:00");
    dateRange = [moment(timeStart).format(FORMAT), currentTimeEnd];
  }
  return [
    {
      dimension: reportConfig.timeDimensions[0].dimension,
      dateRange,
    },
  ];
};

export const getPrevTimeDimension = (reportConfig) => {
  const { compareMode, compareWith } = reportConfig;
  const { dateRange: dateRangeConfig } = reportConfig.timeDimensions[0];
  const { timeStart, timeEnd } = getPrevPeriod(reportConfig);
  let dateRange = [];
  const today = moment().format("YYYY-MM-DD");

  switch (compareMode) {
    case "retail":
      const { compareEnd, compareStart } = compareFechaRetail(
        timeStart,
        timeEnd,
        compareWith
      );
      dateRange = [compareStart, compareEnd];
      break;
    case "past":
      const { compareStart: start, compareEnd: end } = comparePeriodoPasado(
        timeStart,
        timeEnd
      );
      dateRange = [start, end];
      break;
    default:
      dateRange = [
        moment(timeStart)
          .subtract("1", comparatorToTime(compareWith))
          .format(FORMAT),
        moment(timeEnd)
          .subtract("1", comparatorToTime(compareWith))
          .format(FORMAT),
      ];
  }

  const [start, end] = dateRange;
  if (
    dateRangesWithCurrentDay[dateRangeConfig] ||
    moment(timeEnd).isAfter(today) ||
    moment(timeEnd).isSame(today)
  ) {
    let currentTimeEnd = null;
    if (compareMode === "standar") {
      currentTimeEnd = moment()
        .subtract("1", comparatorToTime(compareWith))
        .add(1, "hour")
        .startOf("hour")
        .format("YYYY-MM-DDTHH:00:00");
    } else {
      const hr = moment()
        .add(1, "hour")
        .startOf("hour")
        .format("YYYY-MM-DDTHH:00:00");
      currentTimeEnd = `${moment(end).format("YYYY-MM-DD")}T${
        hr.split("T")[1]
      }`;
    }
    dateRange = [start, currentTimeEnd];
  }
  return [
    {
      dimension: reportConfig.timeDimensions[0].dimension,
      dateRange,
    },
  ];
};

/**
 *  Metodos auxiliares para normalizar las queries de cubejs
 */

/**
 *
 * @param {*} schema
 * @param {*} m_d
 * @returns
 */
export function normalizeDim(schema, d) {
  if (d) {
    return d.map((d) => {
      if (d.type === "object") {
        return `${d.name}`;
      } else return `${schema}.${d.name}`;
    });
  } else return [];
}

export function normalizeMeas(schema, m) {
  const newM = [];
  if (m) {
    const result = m
      .filter((measure) => !CalculatedMeasures[measure.name])
      .map((m) => {
        if (m.type === "object") {
          return `${m.name}`;
        } else return `${schema}.${m.name}`;
      });
    return result;
  }
  return newM;
}

/**
 *
 * @param {*} schema
 * @param {*} order
 * @returns
 */
export function normalizeOrder(schema, order) {
  if (order) {
    return order.map((o) => {
      if (!o[0].includes(".")) return [`${schema}.${o[0]}`, o[1]];
      else return o;
    });
  } else return [];
}

/**
 *
 * @param {*} schema
 * @param {*} filters
 * @returns
 */
export function normalizeFilters(schema, filters) {
  if (filters) {
    return filters.map((f) => {
      if (f.member) {
        return {
          ...f,
          member: `${schema}.${f.member}`,
        };
      } else return f;
    });
  } else return [];
}

/**
 *
 * @param {*} schema
 * @param {*} timeDimensions
 * @returns
 */
export function normalizeTimeDimension(schema, timeDimensions) {
  if (timeDimensions) {
    return timeDimensions.map((td) => ({
      ...td,
      dimension: `${schema}.${td.dimension}`,
    }));
  } else return [];
}

/**
 *
 * @param {dimArray} array
 * @returns
 */
export const extractDimensionsFromObject = (dimArray) => {
  const newArr = [];
  dimArray.forEach((dim) => {
    if (dim.type === "object") {
      dim.properties.forEach((element) => {
        newArr.push({
          name: dim.name,
          type: dim.type,
          ...element,
        });
      });
    } else newArr.push(dim);
  });
  return newArr;
};

export const formatValue = (value, range) => {
  var val = Math.abs(value); //.toFixed(1);
  const signo = value < 0 ? "-" : "";
  if (range) {
    if (range == "M") {
      if (val >= 1000000) {
        val = currencyFormat.format((val / 1000000).toFixed(1)) + " M";
      }
    }
    if (range == "K") {
      if (val >= 1000) {
        val = currencyFormat.format((val / 1000).toFixed(1)) + " K";
      }
    }
  } else {
    if (val >= 1000000) {
      val = currencyFormat.format((val / 1000000).toFixed(1)) + " M";
    }
    if (val >= 1000) {
      val = currencyFormat.format((val / 1000).toFixed(1)) + " K";
    }
  }
  return signo + val;
};

export const getDimMeasures = (array) => {
  return array.map((item) => {
    return Object.assign(
      {},
      { name: Object.keys(item)[0] },
      { ...Object.values(item)[0] }
    );
  });
};

export const removeDuplicated = (filterArray, filters) => {
  let filtersArray = [...filters];
  const newFiltersArray = [];
  if (filterArray.length) {
    filterArray.forEach((filter) => {
      const index = filtersArray?.findIndex(
        (fil) => fil.member === filter.member
      );
      //const existFilter = filtersArray?.find(
      //  (fil) => fil.member === filter.member
      //);
      if (index !== -1) {
        const existFilter = filtersArray[index];
        const existFilters = existFilter?.values ? existFilter.values : [];
        const newFilters = filter?.values ? filter.values : [];
        const newValues = [...new Set([...existFilters, ...newFilters])];
        newFiltersArray.push({ ...filter, values: newValues });
        filtersArray = [
          ...filtersArray.slice(0, index),
          ...filtersArray.slice(index + 1),
        ];
      } else {
        newFiltersArray.push(filter);
      }
    });
    return [...newFiltersArray, ...filtersArray];
  }
  return filters;
};

export function getByCategories(parms, categories) {
  const resp = {};
  parms.forEach((element) =>
    resp[element.category]
      ? (resp[element.category] = [...resp[element.category], element])
      : (resp[element.category] = [element])
  );
  return { ...resp, Todos: resp };
}

export function hasPendingData(userObject) {
  const { accountData, userData } = userObject;
  const name = userData?.name === userData?.email ? undefined : userData?.name;
  const reference = userData?.user_metadata?.reference || null;
  const flow = userData?.user_metadata?.flow;
  const onBoardingData = {
    name,
    tenant_name: accountData?.client,
    reference,
    country: accountData?.country,
    flow,
  };

  return onBoardingData;
}

export const isAvailableForPlan = (userPlan, itemPlan) => {
  const result = PLANS_HIERARCHY[userPlan]?.hierarchy.find(
    (plan) => plan === itemPlan
  );
  return result ? true : false;
};

export const getSubItems = (data, menuItem) => {
  if (data && data.length) {
    return data.map((item) => ({
      ...item,
      route:
        menuItem === "reports"
          ? `/${menuItem}/${item.template_type.toLowerCase()}/${item.id}`
          : `/${menuItem}/${item.id}`,
      active: false,
    }));
  }
};

export const activateItemMenu = (menu, menuKey, subItemId) => {
  const newState = { ...menu };
  const integrationSubitems = {
    marketplaces: "",
    keywords: "",
    publishing: "",
  };
  menuKey = menuKey === "marketplaces" ? "sources" : menuKey;
  Object.entries(newState).forEach(([key, value]) => {
    newState[key].active = key === menuKey;
    if (newState[key].subItems) {
      newState[key].subItems = newState[key].subItems.map((subItem) => ({
        ...subItem,
        active: subItem.id == subItemId,
      }));
    }
  });
  return newState;
};

export function registerEvent(event, properties, context) {
  if (!IS_DEV_MODE) {
    window.analytics.track(event, properties, context);
  }
}

export function registerIdentify(userId, properties) {
  if (!IS_DEV_MODE) {
    window.analytics.identify(userId, properties);
  }
}

export function registerGroup(tenant, properties) {
  if (!IS_DEV_MODE && !IS_DEMO) {
    window.analytics.group(tenant, properties);
  }
}

export const objectArrayDiff = (arr1, arr2, key1, key2) => {
  var difference = arr1.filter(
    (x) => arr2.findIndex((y) => x[key1] === y[key2]) === -1
  );
  return difference;
};

const dateFormattingOptions = {
  weekday: "short",
  day: "numeric",
  month: "short",
  year: "numeric",
};

export function renderDate(checkTimeAndDate) {
  if (!checkTimeAndDate) {
    return "";
  }
  var myDate = new Date(checkTimeAndDate);
  var myFormattedDate = myDate.toLocaleDateString(
    "es-ES",
    dateFormattingOptions
  );
  return myFormattedDate;
}

export const getType = {
  number: "number",
  currency: "number",
  percent: "number",
  time: "date",
  string: "string",
};

export const getCubejsApi = (
  accessToken,
  tenant,
  schema = "Ordersbyitem",
  ignoretenant = false
) => {
  try {
    // Validar parámetros críticos
    if (!accessToken) {
      throw new Error("Access token is required");
    }
    if (!tenant && !ignoretenant) {
      throw new Error("Tenant is required");
    }

    const cubejsApi = cubejs({
      transport: new HttpTransport({
        authorization: `Bearer ${accessToken}`,
        apiUrl: `${CUBEJS_API_URL}`,
        headers: {
          tenant,
          schema,
          ignoretenant,
        },
      }),
    });

    // Validar la configuración del cliente CubeJS
    if (!cubejsApi) {
      throw new Error("Failed to initialize CubeJS API client");
    }

    return { cubejsApi };
  } catch (error) {
    /*registerSentryError(
      error?.message || "Error initializing CubeJS API client"
    );*/
  }
};

export function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
export const URL_BY_COUNTRY = {
  NFCL: {
    CL: "https://www.falabella.com/falabella-cl",
    PE: "https://www.falabella.com.pe/falabella-pe",
  },
  MCL: {
    CL: "https://listado.mercadolibre.cl",
    PE: "https://listado.mercadolibre.com.pe",
    CO: "https://listado.mercadolibre.com.co",
    MEX: "https://listado.mercadolibre.com.mx",
    AR: "https://listado.mercadolibre.com.ar",
    UY: "https://listado.mercadolibre.com.uy",
  },
};

export function validURLByMarketplaces(marketplace, url) {
  const marketplacesUrl = {
    NFCL: [
      "https://www.falabella.com/falabella-cl/category/cat",
      "https://www.falabella.com.pe/falabella-pe/category/cat",
    ],
    MCL: [],
  };
  let isValidUrl = false;
  marketplacesUrl[marketplace].forEach((countryUrl) => {
    if (url.toLowerCase().includes(countryUrl.toLowerCase())) {
      isValidUrl = true;
      return;
    }
  });
  return isValidUrl;
}

export const getMarketplacebyUrl = (url = "") => {
  if (url.toString().trim().startsWith("https://")) {
    const splitUrl = url.split("https://");
    if (splitUrl[1].toLowerCase().includes("falabella.com")) return "NFCL";
    if (splitUrl[1].toLowerCase().includes("articulo.mercadolibre"))
      return "MCL";
    if (splitUrl[1].toLowerCase().includes("mercadolibre")) return "MCL_1";
    return -1;
  } else {
    return -1;
  }
};

export function getSourceKeyFromURL(url = "", country) {
  if (!url || !country) return -1;
  const sourceKey = {
    MCL: "mercadolibre",
    NFCL: "newfalabella",
  };
  const sourceCode = getMarketplacebyUrl(url);
  if (sourceCode === -1) return -1;
  const sk = sourceKey[sourceCode];
  const isValid = RECOMMENDED_MARKETS_BY_COUNTRY[country].indexOf(sk);
  if (isValid === -1) return -1;
  return sk;
}

export function validPubURLByMarketplaces(url, country) {
  const marketplace = getMarketplacebyUrl(url, country);
  if (marketplace === -1) return false;
  const marketplacesUrl = {
    //https://www.falabella.com/falabella-cl/product/113255548/Plancha-Optima-3100-BaByliss-Pro-profesional-alisa-y-ondula/113255549
    NFCL: ["https:", "falabella.com", "falabella-", "product", "pubId"],
    MCL: ["https:", "articulo.mercadolibre", "productId"],
  };
  let isValidUrl = true;
  const urlSplited = url.split("/").filter((s) => s);
  marketplacesUrl[marketplace]?.forEach((item, indx) => {
    if (indx < marketplacesUrl[marketplace].length - 1) {
      if (!urlSplited[indx]?.toLowerCase()?.includes(item))
        return (isValidUrl = false);
    } else {
      if (!urlSplited[indx]) return (isValidUrl = false);
    }
  });
  return isValidUrl;
}

export const extracNFCLPublicationData = (url) => {
  const urlSplited = url.split("/").filter((s) => s);
  let publishing_identifier = urlSplited[4];
  let title = urlSplited[5]?.split("-")?.join(" ");
  return { publishing_identifier, title };
};

export const extracMLCPublicationData = async (url) => {
  const urlSplited = url.split("/").filter((s) => s);
  const [mclCode, mclId, restUrl] = urlSplited.at(2).split("-");
  const response = await fetch(
    `https://api.mercadolibre.com/items?ids=${mclCode + mclId}`
  );
  const [publicationData] = await response.json();

  if (publicationData?.code !== 200) return {};
  else {
    const { id: publishing_identifier, title } = publicationData.body;
    return { publishing_identifier, title };
  }

  /*   const [titleSection, rest] = urlSplited.at(-1).split("-_JM");
  const [code, num, ...restTitle] = titleSection.split("-");
  const cleanTitle = restTitle.join(" ");
  const pubTitle = cleanTitle.charAt(0).toUpperCase() + cleanTitle.slice(1);

  return { publishing_identifier: `${code}${num}`, title: pubTitle }; */
};

export const colores = (position) => {
  if (position >= 1 && position <= 3) return "hot";
  if (position >= 4 && position <= 10) return "hotdown";
  if (position >= 11 && position <= 20) return "alert";
  if (position >= 21 && position <= 48) return "warning";
  if (position < 1 || position > 48) return "bad";
};

export const getColores = (position) => {
  if (position >= 1 && position <= 3) return "#2FBD55";
  if (position >= 4 && position <= 10) return "#2FBD55";
  if (position >= 11 && position <= 20) return "#FF6131";
  if (position >= 21 && position <= 48) return "#FF6131";
  if (position < 1 || position > 48) return "red";
};

export const keywordsColores = (position) => {
  if (position >= 50) return "hot";
  if (position < 2) return "bad";
  //if (position < 20) return "warning";
  if (position < 10) return "alert";
  if (position < 30) return "hotdown";
};

export const getKeywordsColores = (position) => {
  if (position >= 30) return "#2FBD55";
  if (position < 2) return "red";
  //if (position < 20) return "#FF6131";
  if (position < 10) return "#FAC802";
  if (position < 30) return "#2FBD55";
};

export const publishingColores = (position) => {
  if (position > 0) return "hotdown";
  if (position < 1) return "bad";
  //if (position < 5) return "warning";
  //if (position < 7) return "alert";
  //if (position < 10) return "hotdown";
};

export const getpublishingColores = (position) => {
  if (position > 0) return "#2FBD55";
  if (position < 1) return "red";
  // if (position < 5) return "#FF6131";
  // if (position < 7) return "#FF6131";
  // if (position < 10) return "#2FBD55";
};

export const DATES = [
  {
    plan: "free",
    range: dateRanges.Today,
    key: "Today",
    value: "Hoy",
  },
  {
    plan: "free",
    range: dateRanges.Yesterday,
    key: "Yesterday",
    value: "Ayer",
  },
  {
    plan: "free",
    range: dateRanges["This week"],
    key: "This week",
    value: "Esta semana",
  },
  {
    plan: "free",
    range: dateRanges["Last 7 days"],
    key: "Last 7 days",
    value: "Últimos 7 días",
  },
  {
    plan: "free",
    range: dateRanges["Last week"],
    key: "Last week",
    value: "Semana anterior",
  },
  {
    plan: "free",
    range: dateRanges["This month"],
    key: "This month",
    value: "Este mes",
  },
  {
    plan: "free",
    range: dateRanges["Last 30 days"],
    key: "Last 30 days",
    value: "Últimos 30 días",
  },
  {
    plan: "starter",
    range: dateRanges["Last month"],
    key: "Last month",
    value: "Mes anterior",
  },
  {
    plan: "starter",
    range: dateRanges["Last 60 days"],
    key: "Last 60 days",
    value: "Últimos 60 días",
  },
];

export function capitalize(word = "") {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

export function getSourcesRecommendedNames(country, joinBy = "o") {
  const recommended = RECOMMENDED_NAMES_MARKETS_BY_COUNTRY[country];
  const lastSource = recommended?.at(-1);
  const firstSources = recommended?.slice(0, recommended?.length - 1);

  return firstSources?.length
    ? firstSources.join(",") + ` ${joinBy} ` + lastSource
    : lastSource;
}

export const activateToast = (toastData) => {
  return enqueueSnackbar("Your report is ready", {
    variant: "snackbar",
    ...toastData,
  });
};

export function hexToRgbA(hex, opacity) {
  if (!hex) return "transparent";
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");

    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      `,${opacity ?? 1})`
    );
  }
  throw new Error("Bad Hex");
}

export const registerSentryLog = (message) => {
  Sentry.withScope(function (scope) {
    scope.setLevel("debug");

    // The exception has the event level set by the scope (debug).
    Sentry.captureMessage(message);
  });
};

export const registerSentryError = (message) => {
  if (message)
    Sentry.withScope(function (scope) {
      scope.setLevel("error");

      // The exception has the event level set by the scope (error).
      Sentry.captureException(new Error(message));
    });
};

export const parseQueryParamsToJSON = (queryParams) => {
  const paramsJSON = {};
  queryParams.split("&").forEach((query) => {
    const [key, value] = query.split("=");
    return (paramsJSON[key] = value);
  });
  return paramsJSON;
};
export const parseJSONToQueryParams = (jsonParams) => {
  let queryParams = "";
  const maxCaract = Object.keys(jsonParams).length;
  Object.entries(jsonParams).forEach(([key, value], indx) => {
    if (indx < maxCaract - 1) queryParams += `${key}=${value}&`;
    else queryParams += `${key}=${value}`;
  });
  return queryParams;
};

export function areArraysEqual(arr1, arr2) {
  // Verificar si los arreglos tienen la misma longitud
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  // Convertir los arreglos en cadenas de texto
  const str1 = JSON.stringify(arr1);
  const str2 = JSON.stringify(arr2);

  // Comparar las cadenas de texto
  return str1 === str2;
}

// Esta función genera una cadena de consulta a partir de un objeto JSON.
export function generateQueryString(obj) {
  var queryString = "";

  for (var key in obj) {
    if (obj[key] === undefined) continue;
    queryString += key + "=" + obj[key] + "&";
  }
  return queryString.slice(0, -1);
}

export function isMetricFilter(metric, metricList = {}) {
  if (!metric || !Object.keys(metricList).length) return false;
  var result = false;
  for (var element of metricList) {
    const { name } = element;
    if (name === metric) return (result = true);
  }
  return result;
}

export function isValidDate(date) {
  if (!date) return false;
  if (typeof date === "object") {
    const [startDate, endDate] = date;
    if (!startDate || !endDate) return false;
    if (!moment(startDate).isValid()) return false;
    if (!moment(endDate).isValid()) return false;
    if (!moment(startDate, "YYYY-MM-DD", true)) return false;
    if (!moment(endDate, "YYYY-MM-DD", true)) return false;
  } else {
    if (!dateRanges[date]) return false;
  }
  return true;
}

export function printKodkodLog(message = "", data = "") {
  if (IS_DEV_MODE && !IS_DEMO) {
    const now = new Date();
    console.log(
      `KODKOD-${now.toLocaleTimeString()} ${
        typeof message === "object" ? message : message.toUpperCase()
      }`,
      data
    );
  }
}

export const fixQsURLEncoding = (qs) => {
  if (!qs) return undefined;
  const params = qs.split("&");
  let paramsObject = {};
  let parsed = null;
  for (var i = 0; i < params.length; i++) {
    var param = params[i];
    var key = param.split("=")[0];
    var value = param.split("=")[1];

    const decode = decodeURIComponent(value);
    try {
      //si falla el JSON.parse es porque el valor del query parmas no es un json
      parsed = JSON.parse(decode);
      paramsObject[key] = encodeURIComponent(JSON.stringify(parsed));
    } catch (err) {
      parsed = decode;
      paramsObject[key] = encodeURIComponent(parsed);
    }
    //paramsObject[key] = encodeURIComponent(JSON.stringify(parsed));
  }
  return params.length ? paramsObject : undefined;
};

export const filterFiltersByDimension = (
  filters = [],
  dimensionTemplate = []
) => {
  const newFilters = [...filters];
  return newFilters.filter((filter) => {
    if (filter.or) {
      return true;
      //filter.or = filter.or.filter((orFilter) => {
      //  return dimensionTemplate.some(
      //    (dimension) => dimension.name === orFilter.member
      //  );
      //});
      //return filter.or.length > 0;
    } else {
      return dimensionTemplate.some(
        (dimension) => dimension.name === filter.member
      );
    }
  });
};

export function firstLetterToLower(str = "") {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function firstLetterToUpper(str = "") {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const COD_OBJECTS = {
  Codproduct: "Product.codproduct",
  Codvariant: "Variant.codvariant",
};

export const COD_OBJECTS_KEYS = {
  "Product.codproduct": "Codproduct",
  "Variant.codvariant": "Codvariant",
};
export const OBJECTS_KEYS_BY_SOURCE_TYPE = {
  "Product.name": true,
  "Product.codproduct": true,
  "Variant.name": true,
  "Variant.codvariant": true,
  "Category.name": true,
  "Brand.name": true,
};

export const COD_OBJECTS_NAMES = {
  Codproduct: "Product",
  Codvariant: "Variant",
};

export const OBJECTS = {
  brand: "Brand.name",
  category: "Category.name",
  feeStatus: "FeeStatus.name",
  itemStatus: "ItemStatus.name",
  logisticDetail: "LogisticDetail.name",
  logisticType: "LogisticType.name",
  paymentDetail: "PaymentDetail.name",
  paymentStatus: "PaymentStatus.name",
  product: "Product.name",
  codproduct: "Product.codproduct",
  shipmentStatus: "ShipmentStatus.name",
  shopper: "Shopper.name",
  source: "Source.name",
  sourceGroup: "SourceGroup.name",
  sourceType: "SourceType.name",
  variant: "Variant.name",
  codvariant: "Variant.codvariant",
};

export const normalizeReportFilters = (filters = [], filtersLabels = {}) => {
  const filtersKeys = {};
  const standardizedFilters = filters.map((filter) => {
    if (filter?.or) return filter;
    const { member, values, ...restFilter } = filter;
    const [_, objectName] = member.split(".");
    const memberObject = OBJECTS[objectName];
    if (memberObject) {
      const object = firstLetterToUpper(objectName);
      const newValues = filtersLabels[object];

      values.forEach((value, indx) => {
        if (!filtersKeys[object]) filtersKeys[object] = [];
        filtersKeys[object].push(value);
      });
      return {
        member: memberObject,
        values: newValues,
        ...restFilter,
      };
    }
    return filter;
  });
  return { filtersKeys, standardizedFilters };
};

export const MEDFILTERS = {
  "Poco rentable": true,
  "Costo de envío alto": true,
  "Comisiones altas": true,
  "Baja rentabilidad": true,
  "Rentabilidad Negativa": true,
  "Buena rentabilidad": true,
};

export const isValidDateRange = (rangeString) => {
  // Función para verificar si una cadena es una fecha válida
  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };
  const dates =
    typeof rangeString === "object" ? rangeString : rangeString?.split(",");
  if (dates?.length === 2 && isValidDate(dates[0]) && isValidDate(dates[1])) {
    // Asegurarse de que la fecha inicial es anterior a la fecha final
    return new Date(dates[0]) < new Date(dates[1]);
  }
  return false;
};

function compareFechaRetail(startDate, endDate, comparisonMode = "PM") {
  const start = moment(startDate);
  const end = moment(endDate);

  let compareStart = start;
  let compareEnd = end;
  if (comparisonMode === "PM") {
    compareStart = start.clone().subtract(4, "weeks");
    compareEnd = end.clone().subtract(4, "weeks");
  } else if (comparisonMode === "PY") {
    compareStart = start.clone().subtract(52, "weeks"); // 52 semanas atrás para el año anterior
    compareEnd = end.clone().subtract(52, "weeks");
  }

  return {
    compareStart: compareStart?.format("YYYY-MM-DD"),
    compareEnd: compareEnd?.format("YYYY-MM-DD"),
  };
}

function comparePeriodoPasado(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);

  const periodDuration = end.diff(start, "days") + 1; // +1 to include the start date
  const compareStart = start.clone().subtract(periodDuration, "days");
  const compareEnd = end.clone().subtract(periodDuration, "days");

  return {
    compareStart: compareStart.format("YYYY-MM-DD"),
    compareEnd: compareEnd.format("YYYY-MM-DD"),
  };
}

export const uploadCostFile = async (
  e,
  user_id,
  client,
  tenant,
  accessToken,
  email,
  name,
  setFileAction,
  setLoading,
  setFileError,
  fromDate,
  date
) => {
  setFileAction("Cargando archivo...");
  setLoading(true);
  setFileError(null);

  const file = e?.target?.files?.[0] || e.current;
  if (!file) {
    setLoading(false);
    return setFileError("No se ha seleccionado ningún archivo.");
  }

  const formData = new FormData();
  formData.append("user_id", user_id);
  formData.append("client", client.replaceAll(" ", "-"));
  formData.append("tenant", tenant);
  formData.append("token", accessToken);
  formData.append("email", email);
  formData.append("username", name);
  formData.append("tenantName", client);
  formData.append(
    "fromDate",
    fromDate === "custom" ? moment(date).format("YYYY-MM-DD") : null
  );
  formData.append("file", file);

  try {
    const response = await fillCosts(formData);
    if (response.status === 409) {
      setFileError(
        "Estamos procesando tu planilla actual. Por favor, espera a que finalice antes de subir una nueva."
      );
      setLoading(false);
      return;
    }
    if (response.status === 202) {
      const toastData = {
        type: "success",
        title: "Solicitud recibida",
        text: "Ya estamos procesando tu planilla, en cuanto esté listo te notificaremos por correo.",
      };
      enqueueSnackbar("", { variant: "snackbar", ...toastData });
      setLoading(false);
      registerEvent(SEGMENT_EVENT.REQUEST_LOAD_COSTS, {
        name,
        email,
        company: client,
        status: "request sent",
      });
    } else {
      throw new Error("No se pudo iniciar el procesamiento del archivo.");
    }
  } catch (error) {
    setFileError(error.message);
    setLoading(false);
    registerSentryError(
      `Error al procesar la planilla de costos - ${error?.message}`
    );
    registerEvent(SEGMENT_EVENT.REQUEST_LOAD_COSTS, {
      name,
      email,
      company: client,
      status: "request error",
    });
  }
};

export const checkStatus = (taskId, dispatch, handleCostStatus) => {
  const interval = setInterval(() => {
    fetch(`${process.env.REACT_APP_ALMIQUI_URL}/costs/export-status/${taskId}`)
      .then((response) => response.json())
      .then(async (data) => {
        if (data.status === "completed") {
          clearInterval(interval);
          handleCostStatus("completed");
          window.location.href = `${process.env.REACT_APP_ALMIQUI_URL}/costs/download/${taskId}`;
        } else if (data.status === "error") {
          clearInterval(interval);
          handleCostStatus("error");
          registerSentryError(
            `Error en la generación de la planilla de costos en Almiqui`
          );
        }
      })
      .catch((error) => {
        clearInterval(interval);
        registerSentryError(
          `Error al obtener el estado de la generación de la planilla de costos - ${error}`
        );
        handleCostStatus("error");
      });
  }, 5000); // Check every 5 seconds
};

export const handleExport = (tenant, dispatch, handleCostStatus, filter) => {
  fetch(`${process.env.REACT_APP_ALMIQUI_URL}/costs/export-products`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ tenant, filter }),
  })
    .then((response) => {
      if (!response.ok) {
        registerSentryError(
          `Error en solicitdud de planilla a Almiqui - ${response?.menssage}`
        );
        throw new Error(`HTTP error! status: ${response?.menssage}`);
      }
      return response.json();
    })
    .then((data) => {
      checkStatus(data.taskId, dispatch, handleCostStatus);
    })
    .catch((error) => {
      handleCostStatus("error");
      registerSentryError(
        `Error al solicitar la generación de planilla de costos a Almiqui - ${error}`
      );
    });
};

export const handleDownloadCost = async (
  productsCost,
  loading,
  setOpenModal,
  filter
) => {
  setOpenModal(false);

  if (loading) return setOpenModal(true);

  if (!Array.isArray(productsCost) || productsCost.length === 0) {
    alert("No hay productos para descargar");
    return;
  }

  const workbook = new Workbook();
  workbook.creator = "Wivo";
  workbook.lastModifiedBy = "Wivo";
  workbook.created = new Date();
  workbook.modified = new Date();

  const sheet = workbook.addWorksheet("Costos");

  sheet.columns = [
    { header: "Marketplace", key: "source_type", width: 20 },
    { header: "Cuenta", key: "source", width: 15 },
    { header: "ID Cuenta", key: "source_id", width: 25 },
    { header: "ID Producto", key: "external_reference", width: 15 },
    { header: "SKU Producto", key: "codproduct", width: 15 },
    { header: "Nombre Producto", key: "name", width: 50 },
    { header: "Costo de producto (con IVA)", key: "cost", width: 30 },
    { header: "Producto Interno", key: "internalProductName", width: 50 },
    { header: "SKU Producto Interno", key: "internalProductSku", width: 15 },
    { header: "Marca Interna", key: "internalBrand", width: 15 },
    { header: "Categoría Interna", key: "internalCategory", width: 15 },
  ];

  sheet.getColumn("cost").numFmt = "#,##0;[Red]-#,##0";
  sheet.views = [
    {
      state: "frozen",
      xSplit: 0,
      ySplit: 1,
      activeCell: "A2",
    },
  ];

  const header = sheet.getRow(1);
  header.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "E3E5E5" },
  };
  header.height = 20;
  header.font = { name: "arial", size: 12, bold: true };

  const chunkSize = 5000; // Define el tamaño del chunk
  let currentChunk = 0;

  const processChunk = async () => {
    const start = currentChunk * chunkSize;
    const end = Math.min(start + chunkSize, productsCost.length);
    const chunk = productsCost.slice(start, end);

    // Filtrar productos sin costo si filter es true
    const filteredChunk = filter
      ? chunk.filter((product) => !product["ProductCosts.cost"])
      : chunk;

    filteredChunk.forEach((dataSheet, index) => {
      const row = {
        source_type: dataSheet["SourceType.name"],
        source: dataSheet["Source.name"],
        source_id: dataSheet["ProductCosts.source"],
        external_reference: dataSheet["ProductCosts.productExternalReference"],
        codproduct: dataSheet["ProductCosts.productSku"],
        name: dataSheet["ProductCosts.productName"],
        cost: dataSheet["ProductCosts.cost"]
          ? parseInt(dataSheet["ProductCosts.cost"])
          : null,
        internalProductName: dataSheet["ProductCosts.internalProductName"],
        internalProductSku: dataSheet["ProductCosts.internalProductSku"],
        internalBrand: dataSheet["ProductCosts.internalBrand"],
        internalCategory: dataSheet["ProductCosts.internalCategory"],
      };
      const newRow = sheet.addRow(row);
      newRow.font = { name: "arial", size: 10 };

      ["A", "B", "C", "D", "E", "F"].forEach((column) => {
        sheet.getCell(`${column}${index + 2 + start}`).protection = {
          locked: true,
        };
      });
    });

    currentChunk++;
    if (start < productsCost.length) {
      await new Promise((resolve) => setTimeout(resolve, 0)); // Espera un ciclo de evento para evitar bloqueo
      await processChunk(); // Procesa el siguiente chunkk
    } else {
      // Una vez completado el procesamiento de todos los chunks, genera el archivo
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "Costos de productos.xlsx");
    }
  };

  processChunk(); // Inicia el procesamiento del primer chunk
};

export const handleDownloadCard = (
  mode,
  routeObject,
  REPORTS_IDs,
  card,
  reportKey,
  currentOptionKey = null
) => {
  const profitMode = mode ? "TOTAL" : "MARKETPLACE";
  const { origin, href } = window.location;
  const [, fullPath] = href.split(origin);
  const [activeUser, _] = fullPath.split("/#/");

  const protocol = window.location.protocol;
  const host = window.location.host;

  const urlFixed = fixQsURLEncoding(routeObject?.search.split("?")[1]) || {};
  const { date, filters } = urlFixed;
  const query = { date, filters };
  const searchParams = `?${generateQueryString(query)}`;
  const DEV_MODE =
    process.env.REACT_APP_DEV_MODE === "true" ? "STAGING" : "PRODUCTION";

  // Determina el índice del reporte basado en los parámetros
  const reportIndex = currentOptionKey
    ? REPORTS_IDs[card][currentOptionKey][DEV_MODE]
    : REPORTS_IDs[card][profitMode][DEV_MODE];

  const url = `${protocol}//${host}${activeUser}/#/reports/${reportKey}/new=${reportIndex}${searchParams}`;
  window.open(url, "_blank");
};

export function areJSONObjectsEqual(obj1, obj2) {
  // Verificar si alguno de los objetos es undefined
  if (obj1 == null || obj2 == null) {
    return obj1 === obj2;
  }
  // Obtener las claves de ambos objetos
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Verificar que ambos objetos tengan el mismo número de claves
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Verificar que ambas listas de claves sean iguales
  for (let key of keys1) {
    if (!keys2.includes(key) || obj1[key] !== obj2[key]) {
      return false;
    }
  }

  // Si todas las claves y valores coinciden, los objetos son iguales
  return true;
}

export const calculateVariation = (current, prev) => {
  if (current === 0 && prev === 0) {
    return 0; // Si ambos son 0, no hay variación.
  }

  if (prev === 0) {
    // Si prev es 0, cualquier valor positivo en current indica un aumento del 100%.
    return 100;
  }

  // Cálculo normal de variación porcentual
  const rate = ((current - prev) / prev) * 100;
  return parseFloat(rate.toFixed(1));
};

export const formatTime = (time, type = "visto") => {
  // Convertir la fecha UTC a la zona horaria local
  const localTime = moment.utc(time).local();
  const now = moment();
  const duration = moment.duration(now.diff(localTime));

  if (duration.asSeconds() < 0) {
    const futureDuration = moment.duration(localTime.diff(now));
    return `en ${formatDuration(futureDuration)}`;
  }

  return `${type === "visto" ? "hace" : ""} ${formatDuration(duration)}`;
};

const formatDuration = (duration) => {
  if (duration.asSeconds() < 60) {
    return `${Math.floor(duration.asSeconds())} segundo${
      Math.floor(duration.asSeconds()) === 1 ? "" : "s"
    }`;
  } else if (duration.asMinutes() < 60) {
    return `${Math.floor(duration.asMinutes())} minuto${
      Math.floor(duration.asMinutes()) === 1 ? "" : "s"
    }`;
  } else if (duration.asHours() < 24) {
    return `${Math.floor(duration.asHours())} hora${
      Math.floor(duration.asHours()) === 1 ? "" : "s"
    }`;
  } else if (duration.asDays() < 30) {
    return `${Math.floor(duration.asDays())} día${
      Math.floor(duration.asDays()) === 1 ? "" : "s"
    }`;
  } else if (duration.asMonths() < 12) {
    return `${Math.floor(duration.asMonths())} mes${
      Math.floor(duration.asMonths()) === 1 ? "" : "es"
    }`;
  } else {
    return `${Math.floor(duration.asYears())} año${
      Math.floor(duration.asYears()) === 1 ? "" : "s"
    }`;
  }
};

export function getTimeRange(granularity) {
  const now = moment();
  let range = [];

  if (granularity === "24h") {
    for (let i = 0; i < 24; i++) {
      range.push(now.clone().subtract(i, "hours").format());
    }
  } else if (granularity === "7d") {
    for (let i = 0; i < 7; i++) {
      range.push(now.clone().subtract(i, "days").startOf("day").format());
    }
  }

  return range.reverse();
}

export const convertToLocalTime = (data, unit) => {
  const now = moment();
  const start = now.clone().subtract(1, unit);
  const end = now.clone();

  const range = [];
  let current = start.clone();

  while (current.isBefore(end) || current.isSame(end, unit)) {
    range.push(current.clone());
    current.add(1, unit);
  }

  const localData = range.map((time) => {
    const found = data.find((d) =>
      moment
        .utc(d[unit === "hour" ? "hour" : "date"])
        .local()
        .isSame(time, unit)
    );
    return {
      [unit === "hour" ? "hour" : "date"]: time.format(),
      events: found ? found.events : 0,
    };
  });

  return localData;
};

export const splitMarketplaceName = (name) => {
  const index = name.search(/[- ]/);
  if (index === -1) return [name];
  return [
    name.substring(0, index),
    name.substring(index + 1).replaceAll("-", ""),
  ];
};

export const findMilestone = (milestones, milestone) => {
  return milestones.find((mil) => mil === milestone);
};

const numberWords = {
  un: 1,
  uno: 1,
  dos: 2,
  tres: 3,
  cuatro: 4,
  cinco: 5,
  seis: 6,
  siete: 7,
  ocho: 8,
  nueve: 9,
  diez: 10,
  // Agrega más números según sea necesario
};

export const extractNumberFromString = (text) => {
  // Buscar números en formato numérico
  const numericMatch = text.match(/\d+/);
  if (numericMatch) {
    return parseInt(numericMatch[0], 10);
  }

  // Buscar números en formato de texto
  const words = text.split(/\s+/);
  for (const word of words) {
    if (numberWords[word.toLowerCase()]) {
      return numberWords[word.toLowerCase()];
    }
  }

  return null; // Si no se encuentra ningún número
};
